/* For screens larger than 991px */
.header-searchbox {
  margin-right: 10%;
  height: 50px;
  width: 60%;
}

.searcher {
  background-color: white;
  width: 300px;
}
.login-button {
  text-decoration: none !important;
  color: white !important;
  font-size: 20px !important;
}
.header {
  background-color: #8E44AD !important;
}
.header-dropdown {
  width: 150px !important;
  height: 30% !important;
  margin-left: 6%;
  margin-top: 25px;
  margin-bottom: 25px;
  border-radius: 4px;
  background-color: white !important;
  color: black !important;
  border: none !important;
  box-shadow: 0px 4px 6px rgba(242, 240, 240, 0.5) !important;
}
/* .dropdown-items {
  width: 20%;
  font-size: 14px !important;
} */
.dropdown-items:hover {
  background-color: #eee9f0 !important;
  color: #8E44AD !important;
  font-weight: bold;
}
.dropdown-toggle {
  border: none !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2) !important;
}
.login-button:hover {
  font-weight: bolder;
}

/* For smaller screens (max-width: 991px) */
@media (max-width: 991px) {
  .header-options {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
  }

  .header-searchbox {
    margin-top: 10px;
    width: 100%;
  }

  .login-register {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .login-button {
    margin-left: 10px;
  }

  .navbar-collapse {
    align-items: flex-end;
  }

  .header-dropdown {
    width: 100%;
    height: 40px;
    margin-left: 0;
    text-align: center;
  }

  .dropdown-items {
    width: 100%;

  }

  .dropdown-toggle {
    font-size: 14px;
  }

  /* Adjust icon sizes on smaller screens */
  .d-flex > .fa {
    font-size: 18px;
  }
}
.header-second {
  background-color: whitesmoke; /* Adjust based on your design */
  box-shadow: 0 8px 8px -6px rgba(0, 0, 0, 0.9); /* Black shadow with 30% opacity */
  padding: 10px; /* Add padding for spacing */
  
}
.product-categories {
  display: flex;
  gap: 250px; /* Adjust space between buttons */
  margin-left: 10px; /* Space from the dropdown */
}

.product-categories button {
  padding: 0; /* Adjust button padding if necessary */
  font-size: 20px; /* Adjust font size */
  text-decoration: none;
  font-weight: 900;
  color: black;
}

.garmets-category{
/* padding:200px; */
text-decoration:none ;
color: black;
}
.headlines{
  margin-top: 15px;
  margin-bottom:15px ;
}
/* Container styles */
.dropdown-container {
  position: relative;
  display: inline-block;
  text-align: center;
}


/* Initially hide the dropdown content */
.dropdown-content {
  display: none;
  position: absolute;
  top: 70%; /* This ensures the dropdown is directly below the headline */
  left: -78px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  padding: 10px 0;
  width:200px;
  border-radius: 5px;
}

/* On hover, show the dropdown content */
.dropdown-container:hover .dropdown-content {
  display: block;
}

/* Styling for garments items */
.garments-men {
  margin: 5px;
  cursor: pointer;

}

.garmets-category {
  cursor: pointer;
}
.garments-headline{
  font-weight: bolder;
  position: relative;
  cursor: pointer;
  padding-bottom: 5px;
  transition: all 0.3s ease;
  /* width: 150px; */
  
}
.heading-icons{
  height:100px;
  display: flex;
  align-items: center;
 
}
.icons{
  margin-right: 25px;
  font-size: 20px;
}
.garments-headline:hover {
 color:#8E44AD;
  border-bottom: 3px solid #121112; /* Line under the headline on hover */
  /* width: 50px; */
  font-weight:900 ;
  
}
.Mobile-headlines{
  font-weight: bolder;
  position: relative;
  cursor: pointer;
  padding-bottom: 5px;
  transition: all 0.3s ease;
  width: 150px  ;
  /* background-color: aqua; */
  white-space: nowrap;
}
.Mobile-headlines:hover{
  color:#8E44AD;
  border-bottom: 3px solid #121112; /* Line under the headline on hover */
  font-weight:900 ;
}


.dropdown-content p:hover {
  background-color: #f0f0f0; /* Light background on hover */
}

.dropdown-container:hover .dropdown-content {
  display: block;
}