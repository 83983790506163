.home-image {
  height: 52%;
  width: 100%;
  object-fit: cover;
}
.home-page{
  max-width: max-content;
}
.home-images {
  width: 100%;
  height: 485px !important;
  object-fit: cover;
}

.home-subimage {
  height: 235px;
  object-fit: cover;
  width: 100%; /* Ensure full width on smaller screens */
}

/* Adjust heading spacing */
.homepage-heading {
  margin-top: 8%;
  margin-bottom: 3%;
  font-size: 2rem; /* Make heading font-size responsive */
}

/* Ensure 'View' button container is centered and doesn't overflow */
.home-page-view {
  text-align: center;
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.view-image {
  width: 100%; /* Make it responsive */
  position: relative;
  max-width: 300px; /* Max width to maintain design consistency */
}

.view-images {
  height: 300px;
  width: 100% !important;
  object-fit: cover;
}

.view-button {
  background-color: black !important;
  color: white;
  font-size: 15px !important;
  white-space: nowrap;
  border: none !important;
  padding: 10px 20px; /* Add padding for better look */
}

/* Responsive carousel container */
.carousal-container {
  width: 100%;
}

.heghttest {
  width: 100%; /* Ensure it takes full width */
  max-width: 100%; /* Keep it responsive */
}

.heghttest img {
  width: 100%;
  height: 300px;
  object-fit: cover; /* Ensure it doesn't stretch or overflow */
}
/* Responsive card adjustments for smaller screens */
@media (max-width: 768px) {
  .view-image {
    max-width: 100%; /* Make cards more flexible */
    margin: auto; /* Center the cards */
  }

  .view-button {
    padding: 8px 14px;
    font-size: 14px; /* Adjust button font */
  }

  .heghttest img {
    height: 220px; /* Adjust carousel image height for medium screens */
  }
}

/* For very small screens, reduce font size and improve spacing */
@media (max-width: 576px) {
  .home-page {
    padding: 0 10px;
  }

  .homepage-heading {
    font-size: 1.5rem;
  }

  .view-image {
    max-width: 100%; /* Full width for mobile */
  }

  .view-button {
    padding: 6px 12px;
    font-size: 13px; /* Smaller font */
  }

  .heghttest img {
    height: 180px; /* Reduce carousel image height */
  }
}
.carousel-control-prev,
.carousel-control-next {
  display: none;
}
