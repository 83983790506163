.container {
    height: auto; /* Adjust height dynamically */
    padding: 15px; /* Padding to prevent content from touching the screen edges */
    max-width: 1200px; /* Restrict maximum width for better readability on large screens */
    margin: 0 auto; /* Center align the container */}

/* Product image section */
.dresses {
    width: 100%; /* Make the image responsive */
    height: auto;
    margin: 10px 0;
    border-radius: 8px; /* Add slight rounding to the image corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
.carousel {
    margin-top: 20px;
}
.carousel .carousel-item img {
    border-radius: 8px; /* Apply same corner rounding for consistency */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Product name, details, price, and colors */
.product-name,
.product-details,
.product-price,
.product-colors {
    margin-left: 0;
    text-align: center; /* Center-align text for mobile view */
}

/* Adjustments for large screens */
@media (min-width: 768px) {
    .product-name,
    .product-details,
    .product-price,
    .product-colors {
        margin-left: 50px;
        text-align: left; /* Left-align text for larger screens */
    }
}

/* Carousel styling adjustments for smaller screens */
@media (max-width: 760px) {
    .carousel {
        margin-top: 20px;
    }

    .carousel .carousel-item img {
        width: 100%;
        height: auto;
    }
    .view-products.col-md-6 {
        text-align: left; /* Move text to the left */
        padding-left: 15px; /* Optional padding to give space from the edge */
    }

    /* Left-align individual product details */
    .product-name,
    .product-details,
    .product-price,
    .product-colors {
        text-align: left; /* Force left-alignment of text */
        margin-left: 0; /* Remove any leftover margin */
    }
}
.product-name {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
}
.product-details {
    font-size: 1.2rem;
    color: #777;
    margin-bottom: 10px;
  
}
.product-price {
    font-size: 1.5rem;
    font-weight: bold;
    color: #d9534f;
}
.original {
    font-size: 1rem;
    color: #aaa;
    margin-left: 10px;
    text-decoration: line-through;
}
.product-colors,
.product-size {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 10px;
    text-align: left;
}
.colors{
    margin-left: 50px;
}
.button-color{
    width:130px !important;
   margin-right: 10px;
}
.viewproduct-details{
    margin-left:50px;
}
.product-size{
    margin-left:50px;
}
.select-size{
    margin-left:50px;
    
}
.button-size{
    border-radius: 27px;
    margin-right: 10px;
}
.buying-button{
    margin-left:50px;
    width:250px;
    height: 50px;
}
.quantity-selector{
    margin-left:50px;
}
.product-description{
    border:1px solid #ccc;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9
} 