.ezy__signin5 {
  /* Bootstrap variables */
  --bs-body-color: #8e44ad;
  --bs-body-bg: rgb(255, 255, 255);

  /* Easy Frontend variables */
  --ezy-theme-color: #8e44ad;
  --ezy-theme-color-rgb: 13, 110, 253;
  --ezy-form-card-bg: #e7f6ff;
  --ezy-form-card-shadow: none;

  background-color: var(--bs-body-bg);
  overflow: hidden;
}

/* Gray Block Style */
.gray .ezy__signin5,
.ezy__signin5.gray {
  /* Bootstrap variables */
  --bs-body-bg: rgb(246, 246, 246);

  /* Easy Frontend variables */
  --ezy-form-card-bg: #dbf0fd;
  --ezy-form-card-shadow: 0 16px 24px rgba(209, 223, 247, 0.25);
}

/* Dark Gray Block Style */
.dark-gray .ezy__signin5,
.ezy__signin5.dark-gray {
  /* Bootstrap variables */
  --bs-body-color: #ffffff;
  --bs-body-bg: rgb(30, 39, 53);
  --bs-dark-rgb: 255, 255, 255;

  /* Easy Frontend variables */
  --ezy-form-card-bg: #253140;
  --ezy-form-card-shadow: none;
}

/* Dark Block Style */
.dark .ezy__signin5,
.ezy__signin5.dark {
  /* Bootstrap variables */
  --bs-body-color: #ffffff;
  --bs-body-bg: rgb(11, 23, 39);
  --bs-dark-rgb: 255, 255, 255;

  /* Easy Frontend variables */
  --ezy-form-card-bg: #162231;
  --ezy-form-card-shadow: none;
}

.ezy__signin5 .container {
  min-height: 100vh;
}

.ezy__signin5-heading {
  font-weight: bold;
  font-size: 25px;
  line-height: 25px;
  color: var(--bs-body-color);
}

.ezy__signin5-form-card {
  background-color: var(--ezy-form-card-bg);
  border: none;
  box-shadow: var(--ezy-form-card-shadow);
  border-radius: 15px;
}

.ezy__signin5-form-card * {
  color: var(--bs-body-color);
}

.ezy__signin5 .form-control {
  min-height: 48px;
  line-height: 40px;
  border-color: transparent;
  background: rgba(163, 190, 241, 0.14);
  border-radius: 10px;
  color: var(--bs-body-color);
}

.ezy__signin5 .form-control:focus {
  border-color: var(--ezy-theme-color);
  box-shadow: none;
}

.ezy__signin5-btn-submit {
  padding: 12px 30px;
  background-color: #333b7b;
  color: #ffffff;
}
.log-in {
  background-color: #8e44ad !important;
  color: white !important;
}

.ezy__signin5-btn-submit:hover {
  color: #ffffff;
}

.ezy__signin5-btn {
  padding: 12px 30px;
}

.ezy__signin5-btn,
.ezy__signin5-btn * {
  color: #ffffff;
}

.ezy__signin5-btn:hover {
  color: #ffffff;
}

.ezy__signin5-or-separator {
  position: relative;
}

.ezy__signin5-or-separator hr {
  border-color: var(--bs-body-color);
  opacity: 0.15;
}

.ezy__signin5-or-separator span {
  background-color: var(--ezy-form-card-bg);
  color: var(--bs-body-color);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  opacity: 0.8;
}

.login-heading {
  color: #8e44ad;
}
.button-login{
    background-color: #8e44ad !important;
    border: none;
}
.login-forget{
    color: #8e44ad !important;
}
