.footer{
    background-color: #8E44AD !important;
    color: white;
    padding-top: 5%;
    margin-top: 5%;
}
.text-light{
    text-decoration: none;
}
.footer-form{
    border-radius: 5px !important;
}